<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <public-filter
          :action-filter="filterData"
          :reset-filter="resetSearch"
        />
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-col
        cols="auto"
        class="text-right mb-2"
      >
        <b-button
          to="sale_goals/add"
          variant="primary"
        >
          <FeatherIcon icon="PlusIcon" />
          Veri Ekle
        </b-button>
      </b-col>
      <b-card no-body>
        <b-table
          class="font-small-3"
          responsive="sm"
          :fields="fields"
          :items="saleGoals"
          striped
          hover
        >
          <template #cell(brand)="data">
            <div>{{ data.item.brand }}</div>
            <span class="font-small-2 text-warning">{{ data.item.username }}</span>
          </template>
          <template #cell(month)="data">
            <div>{{ moment(data.item.month).format('MMMM') }}</div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              :to="'/sale_goals/edit/' + data.item.id"
              :disabled="data.item.year < moment().format('YYYY')"
            >
              Güncelle
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="saleGoalsCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'
import PublicFilter from '@/views/Sales/Sale_goals/Index/PublicFilter.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    PublicFilter,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'brand',
          label: 'Marka',
        },
        {
          key: 'goal',
          label: 'Hedef',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'daily_sale',
          label: 'Günlük Bağlantı',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'tahsis_baglanti',
          label: 'Tahsis Bağlantı',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'connection',
          label: 'Toplam Bağlantı',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'month',
          label: 'Ay',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'year',
          label: 'Yıl',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'Güncelle',
        },
      ],
      dataQuery: {
        select: [
          'com_sales_goals.id AS id',
          'com_user.name AS username',
          'com_brand.name AS brand',
          'com_sales_goals.goal AS goal',
          'com_sales_goals.daily_sale AS daily_sale',
          'com_sales_goals.tahsis_baglanti AS tahsis_baglanti',
          'com_sales_goals.connection AS connection',
          'com_sales_goals.month AS month',
          'com_sales_goals.year AS year',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    saleGoals() {
      return this.$store.getters['saleGoals/getSaleGoals']
    },
    saleGoalsCount() {
      return this.$store.getters['saleGoals/getSaleGoalsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getSaleGoals()
  },
  methods: {
    getSaleGoals() {
      this.$store.dispatch('saleGoals/saleGoalList', this.dataQuery)
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getSaleGoals()
      }
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.dataQuery.where = {}
      this.getSaleGoals()
    },
    filterData(params) {
      const where = {}
      if (params.id_com_brand) {
        where['com_sales_goals.id_com_brand'] = params.id_com_brand
      }
      if (params.month) {
        where['com_sales_goals.month'] = params.month
      }
      this.dataQuery.where = where
      this.getSaleGoals()
    },
  },
}
</script>

<style scoped>

</style>
